import React, {useEffect, useState} from "react"
import "./courses.css"
import {Global} from "../../global";


const CoursesCard = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    // Fetch data from the API when the component mounts
    fetch(`${Global.Url}/about`)
        .then(response => response.json())
        .then(data => {
          setData(data.data.about); // Assuming the response contains an array of data
        })
        .catch(error => {
          console.error("Error fetching data:", error);
        });
  }, []); // Empty dependency array to fetch data only once when the component mounts
  const [category_data, setCategory] = useState([]);
  useEffect(() => {
    // Fetch data from the API when the component mounts
    fetch(`${Global.Url}/category`)
        .then(response => response.json())
        .then(category_data => {
          setCategory(category_data.data.category); // Assuming the response contains an array of data
        })
        .catch(error => {
          console.error("Error fetching data:", error);
        });
  }, []); // Empty dependency array to fetch data only once when the component mounts
  return (
    <>

      <section className='introduce'>
        {data.slice(0,1).map((item) => (
          <div className='container'>
            <h1>Giới thiệu về Khương Ninh</h1>
            {item.description.split("\n").map((val) => {
                return (
                    <p className='description'>{val}</p>
                )
            })}
          </div>
          ))}
          <div className="container">
          {data.slice(0,1).map((item) => (
          <div className="content2">
              <div className="images">
              <img src={item.image} alt="" />
              </div>
          <div className="des">
              <h1>Tầm nhìn - Sứ mệnh</h1>
              <p>{item.vision}</p>
              <p>{item.mission}</p>
          </div>
          </div>
          ))}
          </div>
          <div className="container">
          <div className="introduce-mar">
          <h1><strong>KHƯƠNG NINH</strong> CUNG CẤP CÁC DỊCH VỤ TRỌN GÓI CHO TỪNG KHÁCH HÀNG</h1>
          <div className="box-container1">
            {category_data.map((val, index) => (
                <div className="box1">
                  <img src={"/images/courses/i"+(index+1)+".png"} alt="" style={{  width: '20%' }} />
                  <h3>{val.name_vi}</h3>
                </div>
              ))}
          </div>
          </div>
          </div>
      </section>

    </>
  )
}

export default CoursesCard
