import React from "react";
import "./blog.css"


export default function Hblog(props) {
  return (
    <div className="Hblog">
      <div className="container">
      <a href={`/tin-tuc/chi-tiet/${props.id}`} className="read_more">
        {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
      <img className="product--image" src={props.url} alt="product image" />
        <div className='admin'>
          <span>
            <i className='fa fa-user'></i>
            <label>{props.type}</label>
          </span>
          <span>
            <i className='fa fa-calendar-alt'></i>
            <label>{props.date}</label>
          </span>
        </div>
        <h2>{props.name.slice(0, 25)}...</h2>
        <p>{props.description.slice(0, 25)}...</p>
        </a>
        <p>
        <a href={`/tin-tuc/chi-tiet/${props.id}`} className="read_more">
          Xem thêm »
        </a>
        </p>
      </div>
    </div>
  );
}
