import React, { useState } from "react"
import "./details.css"
import { useParams } from "react-router-dom"
import { useEffect } from "react"
import {Global} from "../../global";
import parse from "html-react-parser";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { responsive } from "../blog/data";
import Hblog from "../blog/Hblog";
import Back from "../common/back/Back"

export const DetailsPages = () => {
  const { id } = useParams()
  const [data, setData] = useState([]);
  const [relativeBlog, setRelativeBlog] = useState([]);
  useEffect(() => {
    // Fetch data from the API when the component mounts
    fetch(`${Global.Url}/blog/detail?blog_id=`+id)
        .then(response => response.json())
        .then(data => {
          setData([data.data.blog]); // Assuming the response contains an array of data
        })
        .catch(error => {
          console.error("Error fetching data:", error);
        });
  }, [id]); // Empty dependency array to fetch data only once when the component mounts
  const blog_id = data[0]?.['id'];
  useEffect(() => {
    // Fetch data from the API when cate_id changes
      fetch(`${Global.Url}/blog`)
          .then(response => response.json())
          .then(data => {
            setRelativeBlog(data.data.blogs); // Assuming the response contains an array of data
          })
          .catch(error => {
            console.error("Error fetching data:", error);
          });
  }, []);
  const blog = relativeBlog.filter(item => item.id !== blog_id).map((item) => (
      <Hblog
          name={item.title}
          url={item.image}
          description={item.description.toString().substring(0,100)+'...'}
          type = {item.author}
          date = {item.created_at.replace("T", " ")}
          id = {item.id}
      />
  ));
  return (
    <>
    <Back title='Blog Posts' />
      {data.map((item) => (
          <section className='singlePage'>
            <div className='container'>
              <div className='left'>
                <img src={item.cover} alt='' />
              </div>
              <div className='right'>
                <h1>{item.title}</h1>
                <p className="des">{item.description}</p>
                  {parse(item.text)}
              </div>
            </div>
          </section>
      ))}
      <h2 className=" title-blog">You are may also like</h2>
      <Carousel className="container" showDots={true} responsive={responsive}>
        {blog}
      </Carousel>
    </>
  )
}
