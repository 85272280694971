import React, {useEffect, useState} from "react"
import "./style.css"
import {Global} from "../../../global";
import '@fortawesome/fontawesome-free/css/all.min.css';

const Testimonal = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    // Fetch data from the API when the component mounts
    fetch(`${Global.Url}/achievement`)
        .then(response => response.json())
        .then(data => {
          setData(data.data.achievement); // Assuming the response contains an array of data
        })
        .catch(error => {
          console.error("Error fetching data:", error);
        });
  }, []); // Empty dependency array to fetch data only once when the component mounts
  return (
      <>
        <section className='testimonal padding'>
          <div className='container'>
            <div className='section-title'>
              <h2 className='title'>THÀNH TỰU CỦA CHÚNG TÔI</h2>
              <div className="title-line"></div>
            </div>
            <div className='content grid2'>
              {data.map((val) => (
                  <div className='items shadow'>
                    <div className='box flex'>
                      <div className='img'>
                        <img src={val.image} alt='' />
                        <i className="fa fa-quote-left icon"></i>
                      </div>
                      <div className='name'>
                        <h2>{val.title}</h2>
                      </div>
                    </div>
                    <p>{val.description}</p>
                  </div>
              ))}
            </div>
          </div>
        </section>
      </>
  )
}

export default Testimonal
