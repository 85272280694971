import { useState, useEffect, useRef } from "react";
import "./tab.css";
import { Global } from "../../global"

function Tabs() {
    const [toggleState, setToggleState] = useState(1);
    const [categories, setCategories] = useState([]);
    const [services, setServices] = useState([]);
    const firstTabRef = useRef(null);
    const [firstTabClicked, setFirstTabClicked] = useState(false);
    useEffect(() => {
        // Fetch data from the API when the component mounts
        fetch(`${Global.Url}/category`)
            .then(response => response.json())
            .then(categories => {
                setCategories(categories.data.category); // Assuming the response contains an array of data
            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });
    }, []); // Empty dependency array to fetch data only once when the component mounts
    const toggleTab = (cate_id) => {
        setToggleState(cate_id);
        // Fetch data from the API when a button is clicked
        fetch(`${Global.Url}/service/find_by_cate_id?cate_id=${cate_id}`)
            .then(response => response.json())
            .then(services => {
                setServices(services.data.services); // Assuming the response contains an array of data
            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });
    };
    useEffect(() => {
        if (!firstTabClicked && categories.length > 0 && firstTabRef.current) {
            firstTabRef.current.click();
            setFirstTabClicked(true);
        }
    }, [firstTabClicked, categories]);
    return (
        <div className="tab">
            <div className="container1">
            <div className='section-title'>
              <h2 className='title'>Dịch vụ cung cấp</h2>
              <div className="title-line"></div>
            </div>
            <div className="bloc-tabs1">
                {categories.map((item, index) => (
                    <button
                        key={item.id}
                        ref={index === 0 ? firstTabRef : null}
                        className={toggleState === item.id ? "tabs active-tabs" : "tabs"}
                        onClick={() => toggleTab(item.id)}
                    >
                        {item.name_vi} {/* Assuming your API returns a property called 'tabTitle' for each item */}
                    </button>
                ))}
            </div>

            <div className="content-tabs">
                <div className="box-content">
                    {services && services.map((service) => (
                        <div className="box-content-small">
                            <a href={`/dich-vu/chi-tiet/${service.id}`} className="detail">
                                <img src={service.image} alt="" />
                                <h3>{service.name_vi}</h3>
                            </a>
                            <br />
                            <a href={`/dich-vu/chi-tiet/${service.id}`} className="btn">
                                Chi tiết
                            </a>
                        </div>
                    ))}
                </div>
            </div>

        </div>
        </div>
    );

}
export default Tabs;
