import React, {useEffect, useState} from "react"
import "./Service"
import { ServiceHome } from "../../dummydata"
import {Global} from "../../global";
import 'font-awesome/css/font-awesome.min.css';

const ServiceCard = () => {
    const [data, setData] = useState([]);
    useEffect(() => {
        // Fetch data from the API when the component mounts
        fetch(`${Global.Url}/information`)
            .then(response => response.json())
            .then(data => {
                setData(data.data.information); // Assuming the response contains an array of data
            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });
    }, []); // Empty dependency array to fetch data only once when the component mounts
  return (
    <>
      <section className='ServiceHome'>
        <div className='container'>
          <div className='section-title'>
              <h2 className='title'>TRẢI NGHIỆM KHÁCH HÀNG MANG LẠI UY TÍN CHO CHÚNG TÔI</h2>
              <div className="title-line"></div>
          </div>
            <div className='items'>
              {data.map((item, index) => {
                return (
                  <div className='item'>
                    <div className='iconS'>
                    <i class="fa fa-comments-o" aria-hidden="true"></i>
                    </div>
                    <div className='text'>
                      <h2>{item.extra.solutions.title}</h2>
                      <p>{item.extra.solutions.content}</p>
                    </div>
                  </div>
                )
              })}
            {data.map((item, index) => {
                return (
                    <div className='item'>
                        <div className='iconS'>
                          <i class="fa fa-diamond" aria-hidden="true"></i>
                        </div>
                        <div className='text'>
                            <h2>{item.extra.professional.title}</h2>
                            <p>{item.extra.professional.content}</p>
                        </div>
                    </div>
                )
            })}
            {data.map((item, index) => {
                return (
                    <div className='item'>
                        <div className='iconS'>
                          <i class="fa fa-line-chart" aria-hidden="true"></i>
                        </div>
                        <div className='text'>
                            <h2>{item.extra.limit.title}</h2>
                            <p>{item.extra.limit.content}</p>
                        </div>
                    </div>
                )
            })}

            </div>
        </div>
      </section>
    </>
  )
}

export default ServiceCard
