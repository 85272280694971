import React, {useEffect, useState} from "react"
import {Global} from "../../../global";

const Head = () => {
    const [data, setData] = useState([]);
    useEffect(() => {
        // Fetch data from the API when the component mounts
        fetch(`${Global.Url}/information`)
            .then(response => response.json())
            .then(data => {
                setData(data.data.information); // Assuming the response contains an array of data
            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });
    }, []); // Empty dependency array to fetch data only once when the component mounts
    function format_number(phone) {
      let formatted = phone;
      if (phone.length >= 7) {
          formatted = '(' + phone.slice(0, 3) + ') ' + phone.slice(3, -4) + '-' + phone.slice(-4);
      }
      return formatted;
  }
  return (
    <>
      <div className="box-animation">
        <div className="box">
            {data.map((item) => (
                // eslint-disable-next-line jsx-a11y/no-distracting-elements
                <marquee className="marquee" behavior="scroll" direction="left">{item.company_name_vi} | {item.address} -
                    SĐT: {format_number(item.phone_number)}, {format_number(item.extra.phone2)},
                    SĐT bàn: {format_number(item.extra.phone)} </marquee>
                ))}
        </div>
      </div>
      
      <section className='head'>
        <div className='container flexSB'>
            <div className='logo'>
                <a href="/">
                    <img src="/images/logo.jpg" alt="/" />
                    <br />
                    KHƯƠNG NINH
                </a>
            </div>

          <div className='social'>
            <a href="https://www.facebook.com/"><i className='fab fa-facebook-f icon'></i></a>
            <a href="https://www.instagram.com/"><i className='fab fa-instagram icon'></i></a>
            <a href="https://www.twitter.com"><i className='fab fa-twitter icon'></i></a>
            <a href="https://www.google.com/"><i className='fab fa-google icon'></i></a>
            
          </div>
        </div>
      </section>
    </>
  )
}


export default Head
