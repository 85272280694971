import React, { useEffect, useState } from "react";
import "./footer.css";
import { Global } from "../../../global"
import parse from "html-react-parser";

const Footer = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    // Fetch data from the API when the component mounts
    fetch(`${Global.Url}/information`)
      .then(response => response.json())
      .then(data => {
        setData(data.data.information); // Assuming the response contains an array of data
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  }, []); // Empty dependency array to fetch data only once when the component mounts
  const [blog, setBlog] = useState([]);
  useEffect(() => {
    // Fetch data from the API when the component mounts
    fetch(`${Global.Url}/blog`)
      .then(response => response.json())
      .then(data => {
        setBlog(data.data.blogs); // Assuming the response contains an array of data
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  }, []); // Empty dependency array to fetch data only once when the component mounts

  const [customerName, setCustomerName] = useState('');
  const [rate, setRate] = useState(5);
  const [comment, setComment] = useState('');
  const [message, setMessage] = useState(false);
  const [showElement, setShowElement] = React.useState(false);
  
  function format_number(phone) {
    let formatted = phone;
    if (phone.length >= 7) {
        formatted = '(' + phone.slice(0, 3) + ') ' + phone.slice(3, -4) + '-' + phone.slice(-4);
    }
    return formatted;
}
  const handleFormSubmit = (event) => {
    event.preventDefault()
    const feedback = {
      customer_name: customerName,
      rate: rate,
      comment: comment
    };

    fetch(`${Global.Url}/feedback/add`, {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(feedback)
    }).then((response) => response.json())
      .then((data) => {
        // Handle the response if needed
        console.log(data);
        setMessage(true);
        setShowElement(true);
        setTimeout(function () {
          setShowElement(false);
        }, 3000);
        setCustomerName('');
        setRate(5);
        setComment('');
      })
      .catch((error) => {
        // Handle any errors that occurred during the API call
        console.error('Error:', error);
      });
  }

  return (
    <>
      <div className="secSupport__social">
        <a href={`mailto:${data.map((item) => item.email)}`}>
          <img src="/images/mail.png"  alt=""/>
        </a>
        <a href={data.map((item) => (item.extra.zalo))}>
          <img src="/images/zalo.svg"  alt=""/>
        </a>
        <a href={data.map((item) => (item.extra.facebook))}>
          <img src="/images/mess.svg"  alt=""/>
        </a>
        <a className="phone" href={`tel:${data.map((item) => item.phone_number)}`}>
          <img src="/images/call.svg"  alt=""/>
        </a>
      </div>
      <section className="box-container-commit">
        <div className='section-title'>
          <h2 className='title'>Cam kết của chúng tôi</h2>
          <div className="title-line"></div>
      </div>
        <div className="box-container">
          <div className="box">
            <img src="/images/service/1-1.png" alt="" />
            <h1>Chất lượng đảm bảo</h1>
            <p>Khương Ninh cung cấp danh mục quà tặng đa dạng (quà tặng cá nhân, doanh nghiệp,...), dịch vụ quảng cáo ổn định, quy trình rõ ràng cùng trang thiết bị hiện đại phục vụ tối đa nhu cầu khách hàng.</p>
          </div>
          <div className="box">
            <img src="/images/service/1-2.png" alt="" />
            <h1>Giá thành cạnh tranh</h1>
            <p>Chi phí dịch vụ cạnh tranh thị trường, phù hợp với yêu cầu của khách hàng mà vẫn đảm bảo chất lượng sản phẩm, dịch vụ.</p>
          </div>
          <div className="box">
            <img src="/images/service/1-3.jpg" alt="" />
            <h1>Dịch vụ đa dạng</h1>
            <p>Bất cứ khách hàng nào tìm đến Khương Ninh đều nhận được giải pháp hiệu quả với danh mục dịch vụ đa dạng từ quà tặng, dịch vụ quảng cáo, in ấn đến thiết kế - thi công.</p>
          </div>
          <div className="box">
            <img src="/images/service/1-4.png" alt="" />
            <h1>Đảm bảo tiến độ</h1>
            <p>Chúng tôi cam kết tiến độ bàn giao sản phẩm cùng chất lượng với đội ngũ nhân sự lành nghề, chuyên nghiệp trực tiếp thực hiện.</p>
          </div>
        </div>
      </section>

      <div className="container">
      <section className='feedback'>
        <div className='section-title'>
            <h2 className='title'>Thông tin & Kiến thức</h2>
            <div className="title-line"></div>
        </div>
            <div className="row">
                <div className="image">
                  <img  src="/images/feedback.jpg" alt="/"/>
                </div>
                <form action="" onSubmit={handleFormSubmit}>
              <div className="inputBox">
                <input type="text" placeholder='Họ và tên' required={true} value={customerName}
                  onChange={(e) => setCustomerName(e.target.value)} />
              </div>
              <div className="rate">
                <i className={`fa fa-star ${rate >= 1 ? 'active' : ''}`} onClick={() => setRate(1)}></i>
                <i className={`fa fa-star ${rate >= 2 ? 'active' : ''}`} onClick={() => setRate(2)}></i>
                <i className={`fa fa-star ${rate >= 3 ? 'active' : ''}`} onClick={() => setRate(3)}></i>
                <i className={`fa fa-star ${rate >= 4 ? 'active' : ''}`} onClick={() => setRate(4)}></i>
                <i className={`fa fa-star ${rate >= 5 ? 'active' : ''}`} onClick={() => setRate(5)}></i>
              </div>
              <textarea placeholder="Viết đánh giá ở đây" name="" id="" cols="30" rows="10" value={comment}
                onChange={(e) => setComment(e.target.value)} required={true}></textarea>

              <input type="submit" className='btn' value="Gửi" />
              {showElement ? (
                <div className="popup">
                  <br></br>
                  <div class="alert alert-success" role="alert" >
                    {message && (
                        <span>Nộp đánh giá thành công!</span>
                    )}
                    <br />
                  </div>
                </div>
              ): (<div></div>)}{" "}
            </form>
            </div>
        </section>

      </div>

      <footer>
        <div className='container padding'>
          <div className='box logo'>
            <img src="/images/logo.jpg" alt="/" />
            <div className="icons">
              <i className='fab fa-facebook-f icon'></i>
              <i className='fab fa-twitter icon'></i>
              <i className='fab fa-instagram icon'></i>
            </div>
          </div>
          <div className='box link'>
            <h3>Mục Lục</h3>
            <ul>
              <a href="/"><li>Trang Chủ</li></a>
              <a href='/gioi-thieu'><li>Giới Thiệu</li></a>
              <a href='/dich-vu'><li>Dịch Vụ</li></a>
              <a href='/tin-tuc'><li>Bài Viết</li></a>
              <a href='/chinh-sach'><li>Chính Sách</li></a>
            </ul>
          </div>
          <div className='box'>
            <h3>Thông tin & Kiến thức</h3>
            {blog.slice(0, 3).map((val) => (
              <div className='items flexSB_footer'>
                <div className='img'>
                  <img src={val.image} alt='' />
                </div>
                <div className='text'>
                  <span>
                    <i className='fa fa-calendar-alt'></i>
                    <label htmlFor=''>{val.created_at.replace("T", " ")}</label>
                  </span>
                  <span>
                    <i className='fa fa-user'></i>
                    <label htmlFor=''>{val.author}</label>
                  </span>
                  <a href={`/tin-tuc/chi-tiet/${val.id}`}><h4 className="detail">{val.title.slice(0, 40)}...</h4></a>
                </div>
              </div>
            ))}
          </div>
          <div className='box last'>
            <h3>KHUONG NINH GIFTS & ADVERTISING CO.,LTD</h3>
            {data.map((item) => (
              <ul>
                <li>
                  <i className='fa fa-map'></i>
                  {parse(item.address.replace("\n", "<br>"))}
                </li>
                <li>
                  <i className='fas fa-window-restore'></i>
                  {"Mã số thuế: "+item.tax_number}
                </li>
                <li>
                  <i className='fa fa-phone '></i>
                  {format_number(item.phone_number)} <br></br>
                  {format_number(item.extra.phone2)} <br></br>
                  {format_number(item.extra.phone)}
                </li>
                <li>
                  <i className='fa fa-paper-plane'></i>
                  {item.email}
                </li>

              </ul>
            ))}
          </div>
        </div>

        <div className="hotline-phone-ring">
          <div className="hotline-phone">
            <div className="phone-circle"></div>
            <div className="phone-ring-fill"></div>
            <div className="phone-img">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a className="phone" href={`tel:${data.map((item) => item.phone_number)}`}>
                <img src="/images/call.svg" alt="" />
              </a>
            </div>
          </div>
          <div className="hotline">
            {data.map((item) => (
              <a key={item.phone_number} href={`tel:${item.phone_number}`}>
                {item.phone_number}
              </a>
            ))}
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
