export const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1024 },
      items: 4,
      slidesToSlide: 2,
    },
    desktop: {
      breakpoint: { max: 1024, min: 800 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 800, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  
  export const productData = [
    {
      id: 1,
      imageurl:
        "../images/blog/b2.jpg",
      name: "MARKETING TỐT: CHÌA KHÓA THÀNH CÔNG CỦA DOANH NGHIỆP",
      description: "Marketing chính là công cụ quan trọng để xây dựng và phát triển doanh nghiệp. Điều quan trọng là có một chiến lược marketing tốt để tiếp cận và thu hút khách hàng. Công ty XYZ đã",
    },
    {
      id: 2,
      imageurl:
        "../images/blog/b2.jpg",
      name: "MARKETING TỐT: CHÌA KHÓA THÀNH CÔNG CỦA DOANH NGHIỆP",
      description: "Marketing chính là công cụ quan trọng để xây dựng và phát triển doanh nghiệp. Điều quan trọng là có một chiến lược marketing tốt để tiếp cận và thu hút khách hàng. Công ty XYZ đã",
    },
    {
      id: 3,
      imageurl:"../images/blog/b2.jpg",
      name: "MARKETING TỐT: CHÌA KHÓA THÀNH CÔNG CỦA DOANH NGHIỆP",
      description: "Marketing chính là công cụ quan trọng để xây dựng và phát triển doanh nghiệp. Điều quan trọng là có một chiến lược marketing tốt để tiếp cận và thu hút khách hàng. Công ty XYZ đã",
    },
    {
      id: 4,
      imageurl:
        "../images/blog/b2.jpg",
      name: "MARKETING TỐT: CHÌA KHÓA THÀNH CÔNG CỦA DOANH NGHIỆP",
      description: "Marketing chính là công cụ quan trọng để xây dựng và phát triển doanh nghiệp. Điều quan trọng là có một chiến lược marketing tốt để tiếp cận và thu hút khách hàng. Công ty XYZ đã",
    },
    {
      id: 5,
      imageurl:
        "../images/blog/b2.jpg",
      name: "MARKETING TỐT: CHÌA KHÓA THÀNH CÔNG CỦA DOANH NGHIỆP",
      description: "Marketing chính là công cụ quan trọng để xây dựng và phát triển doanh nghiệp. Điều quan trọng là có một chiến lược marketing tốt để tiếp cận và thu hút khách hàng. Công ty XYZ đã",
    },
    {
      id: 6,
      imageurl:
        "../images/blog/b2.jpg",
      name: "MARKETING TỐT: CHÌA KHÓA THÀNH CÔNG CỦA DOANH NGHIỆP",
      description: "Marketing chính là công cụ quan trọng để xây dựng và phát triển doanh nghiệp. Điều quan trọng là có một chiến lược marketing tốt để tiếp cận và thu hút khách hàng. Công ty XYZ đã",
    },
    {
      id: 7,
      imageurl:
        "../images/blog/b2.jpg",
      name: "MARKETING TỐT: CHÌA KHÓA THÀNH CÔNG CỦA DOANH NGHIỆP",
      description: "Marketing chính là công cụ quan trọng để xây dựng và phát triển doanh nghiệp. Điều quan trọng là có một chiến lược marketing tốt để tiếp cận và thu hút khách hàng. Công ty XYZ đã",
    },
  ];
  