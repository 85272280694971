import React, { useState } from "react"
import "./policy.css"
import { useEffect } from "react"
import { Global } from "../../global";
import "react-multi-carousel/lib/styles.css";
import Back from "../common/back/Back"
import parse from "html-react-parser";

export const Policy = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    fetch(`${Global.Url}/policy`)
      .then(response => response.json())
      .then(data => {
        setData([data.data.policies]);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  }, []);
  let polices = []
  polices = data?.[0]
  return (
    <>
      <Back title='Blog Posts' />
      
      <section className='singlePage'>
      <h2 className=" title-blog">Chính Sách Của Chúng Tôi</h2>
        <div className='container'>
          {polices && polices.map((item, index) => (
            <div className="policy">
              <h4>{(index + 1) + ". " + item.name}</h4>
              {parse(item.description)}
            </div>
          ))}
        </div>
      </section>
    </>
  )
}
