import "./service_detail.css"
import Back from "../common/back/Back"
import { React, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Global } from "../../global";
import "react-multi-carousel/lib/styles.css";
import parse from "html-react-parser";
const DetailPagesService = () => {
  const { id } = useParams()
  const [data, setData] = useState([]);
  const [relativeService, setRelativeService] = useState([]);
  useEffect(() => {
    // Fetch data from the API when the component mounts
    fetch(`${Global.Url}/service/detail?service_id=` + id)
      .then(response => response.json())
      .then(data => {
        setData([data.data.service]); // Assuming the response contains an array of data
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  }, [id]);
  const cate_id = data[0]?.['category'];
  useEffect(() => {
    // Fetch data from the API when cate_id changes
    if (cate_id) {
      fetch(`${Global.Url}/service/find_by_cate_id?cate_id=` + cate_id)
        .then(response => response.json())
        .then(data => {
          setRelativeService([data.data.services]); // Assuming the response contains an array of data
        })
        .catch(error => {
          console.error("Error fetching data:", error);
        });
    }
  }, [cate_id]);
  return (
    <>
      <Back title='Blog Posts' />
      <section class="call-to-action">
        <div class="main-content">
          <div class="title">
            <span>DỊCH VỤ CUNG CẤP</span>
            <h4>Dịch vụ in ấn các thương hiệu, banner, và thiết kế quảng cáo</h4>
          </div>
        </div>
      </section>
      <section className="container_service blog-posts">
        <div className="row_service">
          {
            data.map((item) => (
              <div className="col-lg-8">
                <div className="all_content">
                  <div className="row_content">
                    <div class="col-lg-12">
                      <div class="blog-posts">
                        <div class="blog-thumb">
                          <img src={item.image} alt="" />
                        </div>
                        <div class="down-content">
                          <span>Dịch vụ</span>
                          <a href="/"><h4>{item.name_vi}</h4></a>
                          {parse(item.description)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}


          <div class="col-lg-4">
            <div class="sidebar">
              <div class="row_service">
                <div class="col-lg-12">
                  <div class="sidebar-item recent-posts">
                    <div class="sidebar-heading">
                      <h2>Các dịch vụ liên quan</h2>
                    </div>
                    <div class="content">
                      <ul>
                        {
                          relativeService[0] && relativeService[0]
                            .filter(item => item.id !== data[0]?.['id'])
                            .map(item => (
                              <li>
                                <a href={`/dich-vu/chi-tiet/${item.id}`}>
                                  <h5>{item.name_vi}</h5>
                                </a>
                              </li>
                            ))
                        }
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default DetailPagesService