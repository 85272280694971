
import React from "react"
import "./courses.css"
import Heading from "../common/heading/Heading"
import { useState, useEffect } from "react";
import {Global} from "../../global";

const Customer = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    // Fetch data from the API when the component mounts
      fetch(`${Global.Url}/partner`)
        .then(response => response.json())
        .then(data => {
          setData(data.data.partners); // Assuming the response contains an array of data
        })
        .catch(error => {
          console.error("Error fetching data:", error);
        });
  }, []); // Empty dependency array to fetch data only once when the component mounts
  return (
    <>
      <section className='customer'>
        <div className='container'>
          <Heading />
          <h1 className="heading">Khách hàng sử dụng sản phẩm</h1>
          <div className='content grid3'>
            {data.map((item) => (
              <div className='box'>
                <div className='img'>
                  <img src={item.image} alt=''/>
                  <img src={item.hoverCover} alt='' className='show' />
                </div>
                <h1>{item.title}</h1>
                {/*<span>{val.course}</span>*/}
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  )
}

export default Customer

