import React from "react"
import "./Hero.css"

const Hero = () => {
  return (
    <>
      <section className='hero'>
        <div className='container'>
          {/* <div className='row'>
            <Heading subtitle='KHƯƠNG NINH COMPANY' title='Digital Marketing Uy Tín' />
            <p>Marketing tốt khiến công ty trở nên thông minh. Marketing xuất sắc khiến khách hàng cảm thấy thông minh.</p>
            <div className='button'>
              <button href="/" className='primary-btn'>
                <p>Read More ...</p>
              </button>
            </div>
          </div> */}
        </div>
      </section>
    </>
  )
}

export default Hero
