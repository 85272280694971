import React, {useEffect, useState} from "react"
import "../allcourses/courses.css"
import {Global} from "../../global";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';



const HAbout = () => {

    const settings = {
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 550,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const [data, setData] = useState([]);
    useEffect(() => {
        // Fetch data from the API when the component mounts
        fetch(`${Global.Url}/feedback`)
            .then(response => response.json())
            .then(data => {
                setData(data.data.feedback); // Assuming the response contains an array of data
            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });
    }, []); // Empty dependency array to fetch data only once when the component mounts
    const activeFeedback = data.filter(val => val.is_active === true);
    const numActiveFeedback = activeFeedback.length;
    let adjustedSettings = { ...settings };
    if (numActiveFeedback < 4) {
        adjustedSettings.slidesToShow = numActiveFeedback;
        adjustedSettings.slidesToScroll = numActiveFeedback;
    }
    return (
        <>
            <section className='Review'>
            <div className='container'>
                <div className='section-title'>
                    <h2 className='title'>Đánh giá của khách hàng khi sử dụng dịch vụ</h2>
                    <div className="title-line"></div>
                </div>
                <Slider {...adjustedSettings}>
                {activeFeedback.map((val, index) => (
                    <div className='items' key={index}>
                    <div className='content flex'>
                        <div className='text'>
                        <h1>{val.customer_name}</h1>

                        <div className='rate'>
                            {Array(val.rate).fill(1).map((_, i) => (
                            <i className='fa fa-star' key={i}></i>
                            ))}
                            <label htmlFor=''>{val.rate}.0</label>
                        </div>
                        </div>
                    </div>
                    <p className='review' id='collapsed'>
                        {val.comment}
                    </p>
                    </div>
                ))}
                </Slider>
            </div>
            </section>

        </>
    )
}

export default HAbout
