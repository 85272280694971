import React from "react"
import "./service.css"
import Back from "../common/back/Back"
import Tabs from "./Tabs"

const About = () => {
  return (
    <>
      <Back title='Service' />
      <Tabs />
    </>
  )
}

export default About
