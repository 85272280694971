import "./App.css"
import Header from "./components/common/header/Header"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Service from "./components/service/Service"
import CourseHome from "./components/allcourses/CourseHome"
import Blog from "./components/blog/Blog"
import Footer from "./components/common/footer/Footer"
import Home from "./components/home/Home"
import { DetailsPages } from "./components/pages/detailPages"
import detailPagesService from "./components/pages/detailPagesService"
import Page_404 from "./components/home/404/Page_404"
import { Policy } from "./components/policy/policy"
function App() {
  return (
    <>
      <Router>
        <Header />
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/dich-vu' component={Service} />
          <Route exact path='/dich-vu/chi-tiet/:id' component={detailPagesService} />
          <Route exact path='/gioi-thieu' component={CourseHome} />
          <Route exact path='/tin-tuc' component={Blog} />
          <Route exact path='/tin-tuc/chi-tiet/:id' component={DetailsPages} />
          <Route exact path='/chinh-sach' component={Policy} />
          <Route path="*" component={Page_404} />
        </Switch>
        <Footer />
      </Router>
    </>
  )
}

// NhatNghiaComment


export default App
