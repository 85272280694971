import React, { useEffect, useState } from "react"
import { Global } from "../../global";
import Hblog from "./Hblog";
import Carousel from "react-multi-carousel";
import { responsive } from "../blog/data";

const BlogCard = () => {
    const [blog, setBlog] = useState([]);
    useEffect(() => {
        // Fetch data from the API when the component mounts
        fetch(`${Global.Url}/blog`)
            .then(response => response.json())
            .then(blog => {
                setBlog(blog.data.blogs); // Assuming the response contains an array of data
            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });
    }, []); // Empty dependency array to fetch data only once when the component mounts
    const hblog = blog.map((item) => (
        <Hblog
            name={item.title}
            url={item.image}
            description={item.description}
            type={item.author}
            date={item.created_at.replace("T", " ")}
            id={item.id}
        />
    ));
    return (
        <>
            <Carousel className="container" showDots={true} responsive={responsive}>
                {hblog}
            </Carousel>
        </>
    )
}
export default BlogCard
