import React, { useState } from "react"
import Head from "./Head"
import "./header.css"

const Header = () => {
  const [click, setClick] = useState(false)

  return (
    <>
      <Head />
      <header>
        <nav className='flexSB'>
          <ul className={click ? "mobile-nav" : "flexSB "} onClick={() => setClick(false)}>
            <a href="/"><li>Trang Chủ</li></a>
            <a href='/gioi-thieu'><li>Giới Thiệu</li></a>
            <a href='/dich-vu'><li>Dịch Vụ</li></a>
            <a href='/tin-tuc'><li>Bài Viết</li></a>
            <a href='/chinh-sach'><li>Chính Sách</li></a>
          </ul>
          <div className='start'>
            <div className='button'>KHUONG NINH GIFTS & ADVERTISING</div>
          </div>
          <button className='toggle' onClick={() => setClick(!click)}>
            {click ? <i className='fa fa-times'> </i> : <i className='fa fa-bars'></i>}
          </button>
        </nav>
      </header>
    </>
  )
}

export default Header
