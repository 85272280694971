import React, {useEffect, useState} from "react"
import ServiceCard from "../service/ServiceCard"
import HAbout from "./Review"
import Hero from "./hero/Hero"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Hblog from "../blog/Hblog";
import { responsive } from "../blog/data";
import Testimonal from "./testimonal/Testimonal"
import Tabs from "../service/Tabs"
import {Global} from "../../global";


const Home = () => {
    const [data, setData] = useState([]);
    useEffect(() => {
        // Fetch data from the API when the component mounts
        fetch(`${Global.Url}/blog`)
            .then(response => response.json())
            .then(data => {
                setData(data.data.blogs); // Assuming the response contains an array of data
            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });
    }, []); // Empty dependency array to fetch data only once when the component mounts
  const blog = data.map((item) => (
    <Hblog
      name={item.title}
      url={item.image}
      description={item.description}
      type = {item.author}
      date = {item.created_at.replace("T", " ")}
      id = {item.id}
    />
  ));
  return (
    <>
      <Hero />
      <ServiceCard />
      <Tabs />
      <Testimonal />
      <HAbout />
      <div className='section-title'>
          <h2 className='title'>Thông tin & Kiến thức</h2>
          <div className="title-line"></div>
      </div>
      <Carousel className="container" showDots={true} responsive={responsive}>
        {blog}
      </Carousel>
    </>
  )
}

export default Home
