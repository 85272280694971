import React from "react"
import Back from "../common/back/Back"
import BlogCard from "./BlogCard"
import "./blog.css"

const Blog = () => {
  return (
    <>
      <Back title='Blog Posts' />
      <div className='section-title'>
          <h2 className='title'>Thông tin & Kiến thức</h2>
          <div className="title-line"></div>
      </div>
          <BlogCard />
    </>
  )
}

export default Blog
