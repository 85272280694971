import React from "react"

const Back = ({ title }) => {

  return (
    <>
      <section className='back'>
        <div className="Container">
          <div className="text-left">
            
          </div>
    
        </div>
      </section>
      
      <div className="Content">

      </div>
    </>
  )
}

export default Back
